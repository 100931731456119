<template>
  <div>
    <CRow>
      <CCol sm="12">
        <SalesTable
          :items="sales"
          type="cedis"
          :reference="cedis_id"
          hover
          striped
          border
          small
          fixed
          caption="Historial de Ventas"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import SalesTable from '../../components/sales/SalesTable.vue'

import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

import ws from '../../services/sales';
import store from '../../store'

export default {
  name: 'CedisSales',
  components: { SalesTable },
  data: function () {
		return {
            sales: [],
            range: {
              start: new moment().startOf('month').toDate(),
              end: new moment().endOf("month").toDate(),
            },
        }
  },
  mounted: async function() {
    this.loading();

    this.cedis_id = await this.$route.params.id;

    let filters = {
      date : [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")],
      supplier_id : 0,
      cedis_id : this.cedis_id,
      customer_id : 0
    };

    let response = await ws.getFiltered(filters);

    if(response.type == "success"){
      this.sales = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.sales = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
